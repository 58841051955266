<template>
    <div>
<!--        <el-page-header @back="$router.go(-1)" content="现场录入"/>-->
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>分时预约</el-breadcrumb-item>
            <el-breadcrumb-item>现场录入</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :model="form" :rules="rules" ref="form" label-width="150px" class="form">
            <el-form-item label="院区" prop="hospital_id">
                <radio-hospital v-model="form.hospital_id"/>
            </el-form-item>
            <el-form-item label="预约类型" prop="type_id">
                <radio-type v-model="form.type_id" :hospital_id="form.hospital_id"/>
            </el-form-item>
            <el-form-item label="科室" prop="department_id">
                <radio-department v-model="form.department_id" :hospital_id="form.hospital_id" :type_id="form.type_id"/>
            </el-form-item>
            <el-form-item label="医生" prop="doctor_id">
                <radio-doctor v-model="form.doctor_id" :hospital_id="form.hospital_id" :type_id="form.type_id"
                              :department_id="form.department_id"/>
            </el-form-item>
            <el-form-item label="就诊日期" prop="date">
                <radio-date v-model="form.date" :hospital_id="form.hospital_id" :department_id="form.department_id"
                            :doctor_id="form.doctor_id"></radio-date>
            </el-form-item>
            <el-form-item label="就诊时间" prop="time">
                <radio-time v-model="form.time" :hospital_id="form.hospital_id" :department_id="form.department_id"
                            :doctor_id="form.doctor_id" :date="form.date"></radio-time>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="患者姓名" prop="patient">
                        <el-input v-model="form.patient"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="身份证号" prop="idnum">
                        <el-input v-model="form.idnum" @blur="handleIdnum"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="年龄" prop="age">
                        <el-input v-model="form.age"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="7">
                    <el-form-item label="性别" prop="gender">
                        <el-radio v-model="form.gender" label="男">男</el-radio>
                        <el-radio v-model="form.gender" label="女">女</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="既往病史简述" prop="desc">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item>
                <div style="float:right" v-if="$isElectron">
                    <el-button type="primary" @click="readIdCard">读身份证</el-button>
                </div>
                <el-button type="primary" @click="submitForm('form')">预约</el-button>
                <el-button @click="resetForm('form')">重置</el-button>
                <el-checkbox style="margin-left:20px" v-model="form.send_sms">发送短信通知</el-checkbox>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import RadioHospital from "@/components/form/radioHospital";
    import RadioType from "@/components/form/radioType";
    import RadioDepartment from "@/components/form/radioDepartment";
    import RadioDoctor from "@/components/form/radioDoctor";
    import Idcard from "@/plugins/idcard"
    import qs from 'qs'

    export default {
        components: {RadioDoctor, RadioDepartment, RadioType, RadioHospital},
        data() {
            const validateIdnum = (rule, value, callback) => {
                // 输入 8--，value 为 8
                // 估计这里内部使用了 parseInt() / parseFloat()
                let err = Idcard.hasError(value)
                if (value && err) {
                    callback(new Error(err))
                } else {
                    callback()
                }
            }
            return {
                hospitalList: [],
                departmentList: [],
                doctorList: [],
                datesList: [],
                timesList: [],
                form: {
                    source: '',
                    type_id: '',
                    hospital_id: 1,
                    department_id: '',
                    doctor_id: '',
                    date: '',
                    time: '',
                    patient: '',
                    idnum: '',
                    age: '',
                    gender: '',
                    phone: '',
                    desc: '',
                    remark: '',
                    send_sms: false,
                },
                rules: {
                    type_id: [
                        {required: true, message: '请选择类型', trigger: 'change'}
                    ],
                    hospital_id: [
                        {required: true, message: '请选择院区', trigger: 'change'}
                    ],
                    department_id: [
                        {required: true, message: '请选择科室', trigger: 'change'}
                    ],
                    doctor_id: [
                        {required: true, message: '请选择医生', trigger: 'change'}
                    ],
                    date: [
                        {required: true, message: '请选择日期', trigger: 'blur'},
                    ],
                    time: [
                        {required: true, message: '请选择时段', trigger: 'blur'},
                    ],
                    patient: [
                        {required: true, message: '请输入患者姓名', trigger: 'blur'},
                    ],
                    idnum: [
                        {required: false, validator: validateIdnum, trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '请输入联系电话', trigger: 'blur'},
                    ],
                }
            };
        },
        methods: {
            async readIdCard() {
                const data = {action: '--ReadCard'}
                const resp = await this.$http({
                    // url: 'http://10.211.55.3:9432/',
                    url: 'http://127.0.0.1:9432/',
                    method: 'POST',
                    data: qs.stringify(data),
                    transformRequest: [
                        (data, headers) => {
                            delete headers.Authorization;
                            return data;
                        }
                    ]
                })
                if (resp.data.ret === 1) {
                    console.log(resp.data.data)
                    this.form.patient = resp.data.data.name
                    this.form.idnum = resp.data.data.code
                    this.handleIdnum()
                }
            },
            handleIdnum() {
                // console.log(Idcard.hasError(this.form.idnum))
                if (!Idcard.hasError(this.form.idnum)) {
                    this.form.age = Idcard.getAge(this.form.idnum).toString()
                    this.form.gender = Idcard.getGender(this.form.idnum)
                }
            },
            async submitForm() {
                const valid = await this.$refs.form.validate().then(res => res).catch(error => error);
                if (!valid) return
                if (!(this.form.idnum || (this.form.age && this.form.gender))) {
                    this.$message.error("请输入身份证或年龄性别")
                    return
                }
                let method = this.form.id ? 'put' : 'post'
                let url = this.form.id ? `/mingde/appoint/${this.form.id}` : `/mingde/appoint/`
                const resp = await this.$http[method](url, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功！")
                    await this.$router.replace("/main/appoint/index")
                } else {
                    this.$message.error(resp.data.msg)
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            async updates() {
                const hp = await this.$http.get(`/mingde/hospital/`)
                this.hospitalList = hp.data.data || []
                const dp = await this.$http.get(`/mingde/department/?hospital_id=${this.form.hospital_id}&type=${this.form.type}&page=1&size=100`)
                this.departmentList = dp.data.data.data || []
                const dr = await this.$http.get(`/mingde/doctor/?hospital_id=${this.form.hospital_id}&department_id=${this.form.department_id}`)
                this.doctorList = dr.data.data.data || []
                const dt = await this.$http.get(`/mingde/worksheet/getDates?hospital_id=${this.form.hospital_id}&department_id=${this.form.department_id}&doctor_id=${this.form.doctor_id}`)
                this.datesList = dt.data.data || []
                const tm = await this.$http.get(`/mingde/worksheet/getTimes?hospital_id=${this.form.hospital_id}&department_id=${this.form.department_id}&doctor_id=${this.form.doctor_id}&date=${this.form.date}`)
                this.timesList = tm.data.data || []
            },
            async getData() {
                let id = this.$route.query.id
                if (id) {
                    const resp = await this.$http.get(`/mingde/appoint/${id}`)
                    this.form = resp.data.data
                    this.updates()
                }
            }
        },
        mounted() {
            this.getData()
            let source = this.$route.query.s
            if (source) {
                this.form.source = source
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        margin-top: 20px;
    }
</style>
